import { FC } from 'react';
import { WarningOutlined } from '@ant-design/icons';

import { StagingHeader } from './style';

const Header: FC = () => (
  <StagingHeader>
    <WarningOutlined />
    <p>
      WARNING! This is the <strong>{process.env.CUSTOM_ENV}</strong> environment.
    </p>
    <WarningOutlined />
  </StagingHeader>
);

export default Header;
